
import {get,post} from "@/axios";

//订单页面
export function getOrderList(data) {
    return get('api/order/list', data);
}

export function setOrderStatus(data) {
    return post('api/order/setStatus', data);
}


export function exportOrders(data) {
    return post('api/order/export', data);
}

export function sendTgMessage(key, data) {
    var oReq = new XMLHttpRequest();
oReq.open("GET", "https://alarmerbot.ru/?key=" + key + "&message=" + encodeURIComponent(data), true);
console.log("https://alarmerbot.ru/?key=" + key + "&message=" + encodeURIComponent(data));
oReq.send();
}
